import ApiService from "./ApiService";
// const BASE_URL = 'http://35.159.9.64:3002/';
// const BASE_URL = 'http://192.168.29.93:3002/'
// const API_BASE_URL = 'https://orkatravel.udaanpos.in/posApi/';
// const API_BASE_URL = "https://posdemo.udaanpos.in/posApi/";

// export const IMG_PREFIX = localStorage.getItem("img_url")
//   ? localStorage.getItem("img_url")
//   : "https://orkatravel.udaanpos.in/";
// const API_BASE_URL = "https://pos.orkatravel.udaanpos.in/posApi/"
  const API_BASE_URL = "https://orkatravel.udaanpos.in/posApi/";
 export const IMG_PREFIX = "https://orkatravel.udaanpos.in/";
// const API_BASE_URL = "https://posdemo.udaanpos.in/posApi/";
// export const IMG_PREFIX = "https://posdemo.udaanpos.in/";
  // const API_BASE_URL = "https://goodgudi.udaanpos.in/posApi/";
  //   export const IMG_PREFIX = "https://goodgudi.udaanpos.in/";
  //const API_BASE_URL = "https://goodgudi.udaanpos.in/posApi/";

// const API_BASE_URL = localStorage.getItem("backend_url")
//   ? localStorage.getItem("backend_url") + "/posApi/"
//   : "https://posdemo.udaanpos.in/posApi/";

// export const IMG_PREFIX = localStorage.getItem("backend_url")
//   ? localStorage.getItem("backend_url")
//   : "https://posdemo.udaanpos.in/";
export async function getCustomer(data) {
  const { search_text, outlet_id } = data;
  const text = search_text ? search_text : "";
  return ApiService.fetchData({
    url:
      API_BASE_URL +
      `get_customers?search_text=` +
      text +
      `&outlet_id=` +
      outlet_id,
    // url: `https://udaandemo.qrat.net/posApi/get_customers?search_text=Kumar`,
    method: "GET",
    // data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
// export async function getinvoices_post(data) {
//   const { search_text, outlet_id } = data;
//   const text = search_text ? search_text : "";
//   return ApiService.fetchData({
//     url: API_BASE_URL + `getinvoices_post`,
//     // url: `https://udaandemo.qrat.net/posApi/get_customers?search_text=Kumar`,
//     method: "POST",
//     data,
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//       "Access-Control-Allow-Headers":
//         "Content-Type, Authorization, Content-Length, X-Requested-With",
//     },
//   });
// }

export async function getProduct(data) {
  const { search_text, outlet_id } = data;
  const text = search_text ? search_text : "";
  return ApiService.fetchData({
    url:
      API_BASE_URL +
      "get_products?search_text=" +
      text +
      "&outlet_id=" +
      outlet_id,
    // url: `https://udaandemo.qrat.net/posApi/get_customers?search_text=Kumar`,
    method: "GET",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function AddCustomer(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `add_poscustomer_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function GetCouponData(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `get_manualcoupon_post`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function getDeliveryAddress(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `getDeliveryAddress_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function get_accesstoken_status(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `get_accesstoken_status`,
    method: "POST",
    data: { pos_udaan: data },

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function addDeliveryAddress(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `addDeliveryAddress_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function deleteDeliveryAddress(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `deliveryAddress_delete`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function add_pos_invoice_data(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `addPosOrder_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function get_pos_invoices(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `getInvoices_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function get_pos_invoice_details(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `getInvoiceDetails_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function get_pos_hold_invoices(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `getInvoices_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function delete_pos_invoice(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `posOrder_delete`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function convert_pdf_to_xps(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `convert`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function update_pos_customer_api(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `update_poscustomer_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function filter_pos_invoices_api(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `filterInvoice_post`,
    method: "POST",
    data,

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function get_pos_dashboard_data_api(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `getDashboard_post`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function pos_login_api(data) {
  // console.log(data);

  return ApiService.fetchData({
    url: API_BASE_URL + `login_get`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function generate_token_api(data) {
  return ApiService.fetchData({
    url: `https://ap.mswipeota.com/JF/generatetoken`,
    // url: `https://uat.mswipetech.co.in/JF/generatetoken`,

    method: "POST",
    data,
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
    // }
  });
}
export async function generate_pine_lab_token_api(data) {
  return ApiService.fetchData({
    // url: `https://www.plutuscloudservice.in:8201/API/CloudBasedIntegration/V1/UploadBilledTransaction`,

    url: "https://baradariarts.in/pine_initiate_api.php",
    // url: `https://uat.mswipetech.co.in/JF/generatetoken`,

    method: "POST",
    data: { pos_udaan: data },
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    //     'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
    // }
  });
}
export async function CancelBill(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `cancel_bill`,
    method: "POST",
    data: { pos_udaan: data },

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function test_payment_api(data) {
  const param = {
    pos_udaan: data,
  };
  return ApiService.fetchData({
    url: API_BASE_URL + `card_payment_status`,
    method: "POST",
    data: param,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function check_payment_status(data) {
  const param = {
    pos_udaan: data,
  };
  return ApiService.fetchData({
    url: API_BASE_URL + `pinelab_payment_verifydb`,
    method: "POST",
    data: param,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function upd_pay_status_api(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `update_posorderstatus_post`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
export async function change_Payment_status(data) {
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `update_pay_mode`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function get_auth_logo_url_api(data) {
  // console.log(data);
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `pos_load_post`,
    method: "GET",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function add_pos_coupon_api(data) {
  // console.log(data);
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `apply_coupon_post`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}

export async function send_invoice_email_api(data) {
  // console.log(data);
  return ApiService.fetchData({
    // url: `https://udaandemo.qrat.net/posApi/add_poscustomers`,
    url: API_BASE_URL + `send_invoice_email`,
    method: "POST",
    data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":
        "Content-Type, Authorization, Content-Length, X-Requested-With",
    },
  });
}
